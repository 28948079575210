import React from "react"
import Layout from "../components/layout/Layout";
import { graphql } from "gatsby"
import { Navigation } from "../components/navigation/Navigation";
import { VideoHeader } from "../components/layout/VideoHeader";
import { HeaderSize } from "../components/layout/ParallaxHeader";
import { HeaderInfo } from "../components/layout/HeaderInfo";
import { Container } from "../components/layout/Container";
import { StraightContainer } from "../components/layout/StraightContainer";
import Img from "gatsby-image";
import CompanyVideo from "../content/assets/videos/company.mp4";
import { ContainerColour, ContainerSize } from "../components/layout/DiagonalContainer";
import { Hiring } from "../components/sections/index/Hiring";
import { Employee } from "../components/sections/company/Employee";
import { companyPageData } from "../content/pageData/CompanyPageData";
import { BlobOne } from "../components/layout/blobs/BlobOne";
import { BlobThree } from "../components/layout/blobs/BlobThree";

type PageData = {
    data: {
        site: {
            siteMetadata: {
                title: string;
                description: string;
            }
        }
        viking: {
            childImageSharp: any;
        }
        jacob: {
            childImageSharp: any;
        }
        dwiper: {
            childImageSharp: any;
        }
        jcharette: {
            childImageSharp: any;
        }
        jrector: {
            childImageSharp: any;
        }
        cmarrin: {
            childImageSharp: any;
        }
        pkeefner: {
            childImageSharp: any;
        }
        dng: {
            childImageSharp: any;
        }
        lkaiser: {
            childImageSharp: any;
        }
        tkeefner: {
            childImageSharp: any;
        }
        bsayliss: {
            childImageSharp: any;
        }
        eguzman: {
            childImageSharp: any;
        }
        akereliuk: {
            childImageSharp: any;
        }
        kdong: {
            childImageSharp: any;
        }
        allMarkdownRemark: {
            edges: [{
                node: {
                    fields: {
                        slug: string
                    }
                    frontmatter: {
                        title: string
                    }
                }
            }]
        }
    }
}

export default ({ data }: PageData) => {
    const employees = [...companyPageData.employees];

    console.log(employees, data);

    const renderEmployees = () => {
        const employeeElements: JSX.Element[] = [];

        for (let i = 0; i < employees.length; ++i) {
            employees[i].picture = data[employees[i].pictureKey].childImageSharp.fluid;

            const element = <Employee
                key={`employee-${employees[i].name}-${i}`}
                employee={employees[i]}
            />
            employeeElements.push(element);
        }

        return employeeElements;
    }

    return (
        <Layout
            pageTitle={companyPageData.metaPageTitle}
            keywords={companyPageData.metaKeywords}
            description={companyPageData.metaDescription}
            url={"company"}
        >
            <Navigation />
            <VideoHeader size={HeaderSize.SMALL} straightBottom={true} videoSrc={CompanyVideo}>
                <HeaderInfo
                    title={companyPageData.title}
                    className="company centered-header"
                />
            </VideoHeader>
            <Container className="about">
                <div className="blob-container">
                    <BlobOne fill={"#080a2e08"} />
                    <BlobThree fill={"#080a2e08"} />
                </div>
                <div className="copy" data-sal="slide-right">
                    <p className="copy-header">{companyPageData.header}</p>
                    {companyPageData.copy}
                </div>
                <div className="image-container" data-sal="slide-left">
                    <Img fluid={data.viking.childImageSharp.fluid} alt={"Viking from Superstar Slots"} />
                </div>
            </Container>
            <StraightContainer colour={ContainerColour.ORANGE} size={ContainerSize.LARGE} className="employee-container">
                <h2 className="title">{companyPageData.employeesHeader}</h2>
                <div className="employees">
                    {renderEmployees()}
                </div>
            </StraightContainer>
            <Hiring />
        </Layout>
    );
};



export const query = graphql`
    query {
        site {
            siteMetadata {
                title
                description
            }
        }
        viking: file(relativePath: { eq: "assets/images/company/viking.png" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        jacob: file(relativePath: { eq: "assets/images/company/jacob.png" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        dwiper: file(relativePath: { eq: "assets/images/company/dwiper.png" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        jcharette: file(relativePath: { eq: "assets/images/company/jcharette.png" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        cmarrin: file(relativePath: { eq: "assets/images/company/cmarrin.png" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        jrector: file(relativePath: { eq: "assets/images/company/jrector.png" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        pkeefner: file(relativePath: { eq: "assets/images/company/pkeefner.png" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        dng: file(relativePath: { eq: "assets/images/company/dng.png" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        lkaiser: file(relativePath: { eq: "assets/images/company/lkaiser.png" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        jmatthies: file(relativePath: { eq: "assets/images/company/jmatthies.png" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        bsayliss: file(relativePath: { eq: "assets/images/company/bsayliss.png" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        eguzman: file(relativePath: { eq: "assets/images/company/eguzman.png" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        akereliuk: file(relativePath: { eq: "assets/images/company/akereliuk.png" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        kdong: file(relativePath: { eq: "assets/images/company/kdong.png" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
`