import React from "react";

export const companyPageData: CompanyPageData = {
    metaPageTitle: "About Us & Who We Are - iDream Interactive",
    metaKeywords: "idream interactive, idream team, idream company, join idream",
    metaDescription: "iDream Interactive is Windsor’s leading social and mobile game and software development study.  We are passionate about what we do and our community.",
    title: "Who We Are",
    header: "iDream Interactive is a social and mobile game development company located in Windsor, Ontario.",
    copy: (<>
        <p>iDream is Windsor’s leading social and mobile game development studio. They are passionate about what they do and their community. iDream thrives on creating amazing games and apps that are played and enjoyed by millions of people around the world.</p>
        <p>iDream Interactive is a proudly independent and profitable game studio. Our games fund all our new projects, and everyone who works here has a direct impact on the day-to-day business.</p>
        <p>iDream Interactive is made up of some of the most talented, eager and dedicated individuals in the industry. The iDream team is multifaceted, passionate and share the desire to create mind-blowing games and apps on the Facebook and mobile platforms.</p>
    </>),
    employeesHeader: "Meet the Team",
    employees: [
        {
            name: "Jacob Duhaime",
            position: "CEO",
            picture: "",
            pictureKey: "jacob",
        },
        {
            name: "Dave Wiper",
            position: "CTO",
            picture: "",
            pictureKey: "dwiper",
        },
        {
            name: "John Charette",
            position: "COO",
            picture: "",
            pictureKey: "jcharette",
        },
        {
            name: "Jonathan Rector",
            position: "Creative Director",
            picture: "",
            pictureKey: "jrector",
        },
        {
            name: "Chris Marrin",
            position: "Lead Client Developer",
            picture: "",
            pictureKey: "cmarrin",
        },
        {
            name: "Phil Keefner",
            position: "Project Manager",
            picture: "",
            pictureKey: "pkeefner",
        },
        {
            name: "Dyon Ng",
            position: "Lead Game Developer",
            picture: "",
            pictureKey: "dng",
        },
        {
            name: "Lucas Kaiser",
            position: "Technical Artist",
            picture: "",
            pictureKey: "lkaiser",
        },
        {
            name: "Ben Sayliss",
            position: "Game Developer",
            picture: "",
            pictureKey: "bsayliss",
        },
        {
            name: "Esteban Guzman",
            position: "Full-Stack Developer",
            picture: "",
            pictureKey: "eguzman",
        },
        {
            name: "Adam Kereliuk",
            position: "Full-Stack Developer",
            picture: "",
            pictureKey: "akereliuk",
        },
        {
            name: "Kyle Dong",
            position: "Game Developer",
            picture: "",
            pictureKey: "kdong",
        },
        {
            name: "Jill Matthies",
            position: "Project Manager",
            picture: "",
            pictureKey: "jmatthies",
        },
    ],
}

type CompanyPageData = {
    metaPageTitle: string,
    metaKeywords: string,
    metaDescription: string,
    title: string,
    header: string,
    copy: JSX.Element,
    employeesHeader: string,
    employees: EmployeeType[]
}

export type EmployeeType = {
    name: string,
    position: string,
    picture: any,
    pictureKey: string,
}